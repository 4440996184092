import React from "react"
import SEO from "../components/seo"

import { graphql} from "gatsby"
import CompetenceLink from "../components/competence-link"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import ReactPlayer from 'react-player'
import Fade from 'react-reveal/Fade'

import introVideo from '../video/uxo_wheel_light.mp4'

import caseStudies from "../images/case-study.svg"
import SocialLinks from "../components/social-links"

const IndexPage  = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Competences = edges
    .map(edge => <CompetenceLink key={edge.node.id} competence={edge.node} />)

  return (
  <>
    <SEO title="Accueil" />
    <div
      className="lg:flex">
      <section className="lg:pb-1/2 relative w-full lg:w-1/2 bg-bleu">
        <ReactPlayer
        loop
        auto
        width="100%"
        height="100%"
        muted="true"
        playing="true"
        className="lg:absolute object-fit-cover h-full w-full inset-0"
        url={introVideo}/>
      </section>
      <section className="w-full lg:w-1/2 relative bg-jaune flex flex-col lg:flex-row">
        <Fade>
        <div className="w-full lg:w-3/4 my-12 lg:my-auto lg:-m-20 flex flex-col">
          <AniLink
            swipe
            top="exit"
            direction="left"
            to="/a-propos-uxo"
            className="text-white px-3 py-2 font-bold bg-rose self-end hover:bg-violet hover:text-rose">En savoir plus</AniLink>
          <div className="bg-violet text-white">
            <h2 className="py-12 lg:py-1/4 px-8 text-4xl font-bold">Nous développons des <span className="text-rose">projets éditoriaux</span> et des <span className="text-rose">contenus multimédia</span> pour nous, et surtout pour vous.</h2>
          </div>
        </div>
        </Fade>
        <SocialLinks contClass="flex flex-row lg:flex-col lg:w-1/4 m-auto lg:mr-0 pb-8"btnClass="hover:bg-rose bg-violet text-jaune self-center rounded-full h-12 w-12 flex items-center justify-center my-2 mx-2 text-xl"/>
        </section>
    </div>
    <div className="flex flex-col lg:flex-row">
      <section className="w-full lg:w-1/2 relative flex">
        <img src={caseStudies}
          className="w-full h-full object-contain inset-0 z-0" />
        <div className="z-10 absolute ml-20 self-center">
          <h2 className="text-4xl font-bold">Case Studies</h2>
          <h3 className="text-2xl">Nos créations</h3>
          <AniLink className="bg-rose text-jaune font-bold uppercase py-1 px-2">Bientôt</AniLink>
        </div>
      </section>
      <section className="w-full py-8 lg:py-0 lg:w-1/2 relative bg-dark-violet text-white flex flex-col">
        <div className="m-auto w-2/3">
          <Fade top>
          <h2 className="text-4xl font-bold">Ce que l'on fait.</h2>
          </Fade>
          <Fade bottom>
          <p class="text-2xl">Nous définissons et précisons avec vous les contours de votre audience puis déterminons la manière la plus <span className="bg-jaune text-rose">pertinente</span> de s’adresser à elle. Dans tous nos projets, nous nous assurons que votre message est <span className="bg-jaune text-rose">transmis</span> et compris.</p>
          </Fade>
        </div>
      </section>
    </div>
    
    <div className="md:flex max-w-full bg-dark-bleu py-8">{Competences}</div>
  </>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            imageHome {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  }
              }
            }
            path
            title
          }
        }
      }
    }
  }
`